const COMPONENT_NAMES = {
    // Ideally these go in the general order that the steps might appear
    SHOPPING_FOR: 'ShoppingFor',
    EV_CHARGER: 'EvCharger',
    PROPERTY_TYPE: 'PropertyType',
    BUSINESS_CONTACT: 'BusinessContact',
    OWNS_PROPERTY: 'OwnsProperty',
    OWNS_PROPERTY_COMMERCIAL: 'OwnsPropertyCommercial',
    TIME_TO_PURCHASE: 'TimeToPurchase',
    RENT_CRITERIA: 'RentCriteria',
    CSM_HANDOFF: 'CsmHandoff',
    SUSTAINABILITY: 'Sustainability',
    ELECTRICITY_BILL: 'ElectricityBill',
    SERVICE_ERROR: 'ServiceError',
    STORAGE_INTEREST: 'StorageInterest',
    STORAGE_REASON: 'StorageReason',
    ADDRESS: 'Address',
    ROOF_PIN: 'RoofPin',
    ROOF_PIN_HELP: 'RoofPinHelp',
    ROOF_AGE: 'RoofAge',
    REMOVE_TREES: 'RemoveTrees',
    ORGANIZATION_NAME: 'OrganizationName',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    VERIFY_COMPLETE: 'VerifyComplete',
    SCHEDULE_CALL: 'ScheduleCall',
    HEAT_PUMPS_INSTALLER_SEARCH: 'HeatPumpsInstallerSearch',
};

const CHOICE_VALUES = {
    ROOFTOP: 'rooftop',
    EV: 'ev',
    HEATPUMP: 'heatpump',
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONPROFIT: 'nonprofit',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    BACKUP: 'EBP',
    SAVINGS: 'UR',
    SELF_SUPPLY: 'SS',
    OLD_ROOF: 'more_than_20',
    NEW_ROOF: 'less_than_20',
    OLD_ROOF_REPLACE: 'more_than_20_replacing',
    YES_REMOVE: 'yes',
    NO_REMOVE: 'no',
    OWNER: 'owner',
    NON_OWNER: '',
    RENT_OTHER: 'shopping_someone_else',
    RENT_HOA: 'shopping_hoa_or_multicondo',
    RENT_SELF: 'rent_for_self',
    REPRESENT_OWNER: 'represent_owner',
    COMMERCIAL_OWNER: 'owner',
    COMMERCIAL_LEASE: 'commercial_lessee',
    WITHIN_MONTHS: 'next_few_months',
    WITHIN_YEAR: 'next_year',
    NOT_SURE: 'unsure',
    TRUE: 'true',
    FALSE: 'false',
};

export const FINAL_STEPS = [COMPONENT_NAMES.VERIFY_COMPLETE, COMPONENT_NAMES.CSM_HANDOFF];

export const CHOICE_STEP_CHANGES = {
    SHOPPING_FOR: {
        ROOFTOP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: true },
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
            { ADDRESS: true },
            { ROOF_PIN: true },
            { NAME: true },
            { EMAIL: true },
            { PHONE: true },
            { VERIFY_COMPLETE: true },
        ],
        EV: [
            { EV_CHARGER: true },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
        HEATPUMP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
    },
    EV_CHARGER: {},
    PROPERTY_TYPE: {
        RESIDENTIAL: [
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
        ],
        COMMERCIAL: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
        NONPROFIT: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
    },
    OWNS_PROPERTY: {
        TRUE: [{ RENT_CRITERIA: false }],
        // Rent means a personal non-commercial lease, so the residential rent criteria step is shown
        FALSE: [{ RENT_CRITERIA: true }],
    },
    OWNS_PROPERTY_COMMERCIAL: {},
    TIME_TO_PURCHASE: {},
    RENT_CRITERIA: {
        RENT_OTHER: [
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
        ],
        RENT_HOA: [{ STORAGE_INTEREST: true }, { REMOVE_TREES: true }, { ROOF_AGE: true }, { ELECTRICITY_BILL: true }],
        RENT_SELF: [
            { STORAGE_INTEREST: false },
            { STORAGE_REASON: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
        ],
    },
    CSM_HANDOFF: {},
    ELECTRICITY_BILL: {},
    STORAGE_INTEREST: {
        INTERESTED: [{ STORAGE_REASON: true }],
        NOT_INTERESTED: [{ STORAGE_REASON: false }],
    },
    STORAGE_REASON: {},
    ADDRESS: {},
    ROOF_PIN: {},
    ROOF_AGE: {},
    REMOVE_TREES: {},
    ORGANIZATION_NAME: {},
    NAME: {},
    EMAIL: {},
};

const NUMERIC_CONSTANT_VALUES = {
    CO2_TONS_PER_KWH: 0.0007601,
    CO2_TREES_PER_KWH: 0.0176808,
    AVG_NATIONAL_ELECTRICITY_BILL: 23, // 23 cents/kwh
};

const SERVICE_LEVEL_VALUES = {
    FULL: 'full',
    LIMITED: 'limited',
    NONE: 'none',
};

const handler = {
    get(target, name) {
        if (name in target) {
            return target[name];
        }
        throw new Error(`invalid key: ${name}`);
    },
};

export const COMPONENTS = new Proxy(COMPONENT_NAMES, handler);
export const COMPONENT_COUNT = Object.keys(COMPONENT_NAMES).length;
export const CHOICES = new Proxy(CHOICE_VALUES, handler);
export const NUMERIC_CONSTANT = new Proxy(NUMERIC_CONSTANT_VALUES, handler);
export const SERVICE_LEVEL = new Proxy(SERVICE_LEVEL_VALUES, handler);
export const COMMERCIAL_PROPERTY_TYPES = [CHOICES.COMMERCIAL, CHOICES.NONPROFIT];

export const MARKET_URL = '/market/';
export const ES_SITE_MARKET_START_PATH = '/market/start/';

export const PROPERTY_OVERRIDE_COOKIE_NAME = 'mfdp_mvp_property_type_override';
const COOKIE_PATH = '/';
const MAX_AGE_IN_SECONDS = 15;
const SAME_SITE_RULE = 'lax';
export const PROPERTY_OVERRIDE_COOKIE_PARAMS = {
    path: COOKIE_PATH,
    maxAge: MAX_AGE_IN_SECONDS,
    sameSite: SAME_SITE_RULE,
};
export const ERROR_REASON = {
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    SUBMISSION: 'SUBMISSION',
    COOKIE_AUTHORIZATION: 'COOKIE_AUTHORIZATION',
    NO_SERVICE: 'NO_SERVICE',
    UNKNOWN: 'UNKNOWN',
};

export const GOOGLE_MAPS_API_MAX_LOAD_TIME = 1500;

export const GOOGLE_MAPS_API_LIBRARIES = {
    CORE: 'core',
    MAPS: 'maps',
    MARKER: 'marker',
    PLACES: 'places',
};

export const HP_EA_PHONE_NUMBER = '(888) 838-4638';

export const NEXSTAR_ZIP_CODES = new Set(['02131']);
